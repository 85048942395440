<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="12">
          <el-form>
            <el-form-item label="视频启用开关">
              <el-switch v-model="form.video_enable"></el-switch>
            </el-form-item>
            <el-form-item label="pdf导入接口 Api Secret">
              <el-input v-model="form.pdf_import_api_secret"></el-input>
            </el-form-item>
            <el-form-item label="画册列表页背景色">
              <y_choose_color v-model="form.album_list_bg_color"></y_choose_color>
            </el-form-item>
            <el-form-item label="首页分享标题">
              <el-input v-model="form.index_share_title"></el-input>
            </el-form-item>
            <el-form-item label="首页分享图">
              <y_upload_img v-model="form.index_share_pic"></y_upload_img>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">提交</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import y_choose_color from "@/components/y-form/y_choose_color";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "basic",
  components: {y_upload_img, y_choose_color},
  data(){
    return{
      form:{
        video_enable:false,
        album_list_bg_color:"",
        index_share_title:"",
        index_share_pic:"",

        pdf_import_api_secret:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$u.api.album.setting().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.album.settingEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>

</style>